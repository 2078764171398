<template>
  <div class="row">

    <div class="col-md-4">
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style='height: 350px; width: 350px; position: relative'>
          <img
            class="image"
            :src="[uploadedImageUrl == '' ? form.image_name : uploadedImageUrl]"
            @load="onImageLoad()"
          >
        </div>
      </div>
    </div>

    <div class="col-md">
      <b-form
        @submit.stop.prevent="formOnSubmit"
        enctype="multipart/form-data"
      >

        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Description -->
        <b-form-group id="input-group-description">
          <label for="input-description">Deskripsi: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Deskripsi"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
        </b-form-group>

        <!-- Image -->
        <b-form-group id="input-group-image">
          <label for="input-image">Gambar: <em class="text-muted">opsional</em></label>
          <b-form-file
            v-model="form.image"
            placeholder="Upload Gambar"
            drop-placeholder="Drop file here..."
            @change="onFileUpload"
          ></b-form-file>
          <small class="text-danger">{{ error.image }}</small>
        </b-form-group>

        <!-- Button -->
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/expense/list')"
        >
          Batal
        </b-button>
      </b-form>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    form: Object,
    route: String
  },

  data() {
    return {
      // Error
      error: {
        image: '',
        name: '',
        description: ''
      },
      // Others
      imgLoaded: false,
      uploadedImageUrl: '',
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0])
      this.form.photo = evt.target.files[0]
    },

    async formOnSubmit() {
      // Make Request
      let formData = new FormData()
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value)
      }
      let response = await module.submit(formData, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/masters/payment-methods')
      }
    }

  }

}
</script>

<style>
</style>